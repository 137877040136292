<template lang="html">
    <section class="list-component">
        <template v-if="item.items"><pre>{{item.items}}</pre>
            <li>
                <p v-html="item.content "></p>
                <template v-if="listStyle == 'ordered'">
                    <ol>
                        <listComponent :item="item" v-for="(item, index) in Object.assign({}, item.items)" :key="index" :items="item.items" :listStyle="listStyle" />
                    </ol>
                </template>
                <template v-else>
                    <ul>
                        <listComponent :item="item" v-for="(item, index) in Object.assign({}, item.items)" :key="index" :items="item.items" :listStyle="listStyle" />
                    </ul>
                </template>
            </li>
        </template>
        <template v-else>
            <li>
                <p v-html="item"></p>
            </li>
        </template>
    </section>
</template>

<script lang="js">
    import listComponent from '@/components/list-component.vue'
    export default {
        name: 'list-component',
        props: ['item', 'listStyle'],
        components: {
            listComponent
        },

        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {

        }

    }
</script>

<style scoped lang="scss">

</style>
