<template>
  <div class="newsletter-body">
    <section v-for="(el, key) in json.blocks" :key="key">

      <div v-if="el" class="contenedor-texto">
        <!-- Paragraph -->
        <div v-if="el.type == 'paragraph'" class="paragraph">
          <p v-html="el.data.text"></p>
        </div>

        <!-- Image -->
        <template v-else-if="el.type == 'image'">
          <div class="div-image">
            <img :src="el.data.file.url" class="img-fluid" :alt="el.data.caption" />
            <!-- <span v-html="el.data.caption" class="pie"></span> -->
          </div>
        </template>

        <!-- Header -->
        <h4 :nivel="el.data.level" v-else-if="el.type == 'header'" class="destacado" v-html="el.data.text"></h4>

        <!-- Embed -->
        <div class="container-video" v-if="el.type == 'embed'" :data-service="el.data.service">
          <iframe :src="el.data.embed"></iframe>
        </div>
        <!-- File -->
<!--         <div class="list-download" v-else-if="el.type == 'attaches'">
          <a download :href="el.data.file.url" class="btn-blue-border" rel="noopener" target="_blank"> Descargar archivo
            adjunto</a>
        </div> -->
        <div v-else-if="el.type == 'attaches'" class="descargable row-center">
          <a href="https://back.continentalautomotive.es/catalog/editor/files/6666b69009886_10062024prvdoguia-sanciones.docx"
            rel="noopener" download="Nota de prensa.docx" target="_blank" class="enlace-cover"></a>
          <p>Nota de prensa.docx <a data-v-3e05c038="" href="" class="download-link">Descargar</a></p>
        </div>

        <!-- Slider -->
        <template v-else-if="el.type == 'gallery'">
          <h2 class="title-grand m-top-40-px gallery">Galería</h2>
          <agile :options="myOptions" ref="carousel" class="container carrousel-2 carrousel-home galery">
            <div class="slide" v-for="(img, key) in el.data.images" :key="key"
              @click="openPopUp(img.caption, img.file.url)">
              <div class="img-content" :style="`background-image:url(` + img.file.url + `)`" :title="img.caption"></div>
              <!-- <img class="img-fluid" :src="img.file.url" :alt="img.caption" /> -->
              <span>{{ img.caption }}</span>
            </div>
          </agile>
          <section class="mask-popup" v-if="popup">
            <div class="container-popup">
              <div class="container shadow">
                <button class="close" @click="closePopup">CERRAR</button>
                <img class="img-main" :src="urlImageSelected" :alt="descriptionImageSelected" />
                <p class="description">
                  {{ descriptionImageSelected }}
                </p>
                <div class="carrousel-2 carrousel-expo">
                  <agile :options="myOptionsPopup" ref="carousel" class="carrousel-home">
                    <div class="slide" v-for="(img, key) in el.data.images" :key="key"
                      @click="openPopUp(img.caption, img.file.url)">
                      <div class="img-expo" :style="`background-image:url(` + img.file.url + `)`" :title="img.caption">
                      </div>
                      <!-- <img class="img-fluid" :src="img.file.url" :alt="img.caption" /> -->
                      <span>{{ img.caption }}</span>
                    </div>
                  </agile>
                </div>
              </div>
            </div>
          </section>
        </template>
        <!-- Prety Link -->
        <div class="preaty-link row-center" v-if="el.type == 'linkTool'">
          <div class="link w-100">
            <a :href="el.data.link" rel="noopener" :target="[el.data.blank == true ? '_blank' : '_self']">
              <strong>{{ el.data.meta.title }}</strong>
              <!-- <span v-if="el.data.meta.description"> ({{ el.data.meta.description }})</span> -->
            </a>
          </div>
        </div>

        <!-- List -->
        <section v-else-if="el.type == 'list' || el.type == 'nestedList'">
          <template v-if="el.data.style == 'ordered'">
            <ol>
              <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item"
                :listStyle="el.data.style" />
            </ol>
          </template>
          <template v-else>
            <ul>
              <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item"
                :listStyle="el.data.style" />
            </ul>
          </template>
        </section>

        <!-- Boton -->

        <div class="button" v-else-if="el.type == 'button'">
          <a target="_blank" :href="el.data.link" class="btn-blue-border">{{ el.data.text }}</a>
        </div>

        <!-- Tabla -->
        <div class="table-container row-center" v-else-if="el.type == 'table'">
          <table>
            <tr v-for="(td, tdkey) in el.data.content" :key="tdkey">
              <td :key="ekey" v-for="(e, ekey) in td" v-html="e"></td>
            </tr>
          </table>
        </div>

        <!-- Columnas -->
        <div class="grid-2" v-else-if="el.type == 'columns'">
          <div class="grid" v-for="(el, subkey) in el.data.itemContent" :key="subkey">
            <editorjs :object="el"></editorjs>
          </div>
        </div>

        <!-- Raw -->
        <div v-else class="raw">
          <div v-html="el.data.html"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";
import listComponent from "@/components/list-component.vue";
export default {
  name: "editorjs",
  props: ["object"],

  data: () => ({
    popup: false,
    descriptionImageSelected: "",
    urlImageSelected: "",
    myOptions: {
      responsive: [
        {
          breakpoint: 900,
          settings: {
            navButtons: true,
            slidesToShow: 4,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            navButtons: true,
            slidesToShow: 2,
            dots: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            navButtons: true,
            slidesToShow: 1,
            dots: false,
          },
        },
      ],
    },
    myOptionsPopup: {
      responsive: [
        {
          breakpoint: 900,
          settings: {
            navButtons: true,
            slidesToShow: 5,
            dots: false,
            centerMode: true,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 800,
          settings: {
            navButtons: false,
            slidesToShow: 2,
            dots: true,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            navButtons: false,
            slidesToShow: 2,
            dots: true,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            navButtons: false,
            slidesToShow: 1,
            dots: true,
            autoplay: false,
          },
        },
      ],
    },
  }),

  components: {
    listComponent,
    agile: VueAgile,
  },
  methods: {
    openPopUp: function (element, url) {
      this.urlImageSelected = url;
      this.descriptionImageSelected = element;
      this.popup = true;
    },
    closePopup: function () {
      this.popup = false;
    },
  },
  computed: {
    json() {
      if (typeof this.object === "string" || this.object instanceof String) {
        return JSON.parse(this.object);
      }
      return this.object;
    },
  },
};
</script>
<style scoped lang="scss">
p a,
a {
  color: #ffa500 !important;
}

a {
  word-break: break-all;
}

ol {
  counter-reset: item;
}

ol li {
  display: block;
}

.button {
  margin-bottom: 20px;
}

ol li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

h4 {
  margin-bottom: 15px;
}

.title-grand.gallery {
  margin-bottom: -60px;
}

.slide {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.slide .img-content {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  cursor: pointer;
}

.slide img {
  width: 100%;
}

.agile__actions {
  position: absolute;
  bottom: 20px;
  width: 100%;

  button {
    background: white;
    border: 2px solid blueLight;
    border-radius: 100%;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    padding: 0;
  }
}

.agile__dot--current button {
  background: blueLight;
}

.destacado {
  font-size: 20px;
  text-align: left;
  font-weight: 800;
  line-height: 1.4em;
  margin-bottom: 15px;
  color: #ffa500;

  &[nivel="1"] {
    font-size: 24px;
    max-width: 670px;
    padding-left: 45px;
    position: relative;
    margin-bottom: 25px;
    margin-top: 15px;
  }

  &[nivel="2"] {
    font-size: 22px;
  }

  &[nivel="3"] {
    font-size: 20px;
  }

  &[nivel="4"] {
    font-size: 18px;
  }

  &[nivel="5"] {
    font-size: 16px;
  }

  &[nivel="6"] {
    font-size: 14px;
  }
}

.descargable {
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffa500;

  p {
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .download-link {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 300;
    color: #ffa500;
    text-decoration: none;
    margin-left: 20px;

    &::after {
      width: 25px;
      height: 25px;
      background: url(/img/file.svg) center/100% no-repeat;
      content: "";
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.paragraph {
  p {
    margin-bottom: 10px;

    a {
      word-break: break-all;
    }
  }
}

.div-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  img {
    width: auto;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}
</style>
