<template>
    <div class="wrapper -sala-de-prensa">

        <div class="encabezado">
            <div class="container">
                <h2>Sala de prensa</h2>
            </div>
        </div>



        <section class="ficha-recurso container" v-if="data">

            <div class="top" v-if="data">  
                <h3 class="fecha">{{data.created_at | parsedate}}</h3>
                <h2>{{data.title}}</h2>
                <p class="entradilla" v-html="data.header"></p>
                <img :src="data.header_img.url" v-if="data.header_img.url" :alt="data.title">    
            </div>

            <div class="bottom" v-if="data">
                <EditorJs :object="data.object" />
            </div> 

        </section>

        <div class="loading" v-else></div>



    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    import moment from 'moment';


/*     import sliderecurso from '@/components/slider-recurso';
 */    import EditorJs from "@/components/editorJs";

    export default {
        name: 'ficha-recurso',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: ''
        }),
        methods: {
            ...mapActions(['requestEntrie']),
            setData() {
                this.requestEntrie(this.$route.params.id).then(r => {
                    this.data = r;
                    document.title = (this.data.seoTitle) ? this.data.seoTitle : this.data.titulo;
                    document.head.querySelector("[name=description]").content = (this.data.seoDescription) ? this.data.seoDescription : this.data.entradilla.split(0, 100);


                    if (this.data.img.enlace) {
                        document.head.querySelector("[property='og:image']").content = this.data.img.url;
                        document.head.querySelector("[name='twitter:image']").content = this.data.img.url;
                    }

                    document.head.querySelector("[property='og:title']").content = (this.data.seoTitle) ? this.data.seoTitle : this.data.titulo;
                    document.head.querySelector("[name='twitter:title']").content = (this.data.seoTitle) ? this.data.seoTitle : this.data.titulo;
                    document.head.querySelector("[name='twitter:site']").content = location.href;
                    document.head.querySelector("[property='og:description']").content = (this.data.seoDescription) ? this.data.seoDescription : this.data.entradilla.split(0, 100);
                    document.head.querySelector("[name='twitter:description']").content = (this.data.seoDescription) ? this.data.seoDescription : this.data.entradilla.split(0, 100);

                    window.prerenderReady = true;
                })
            }
        },
        components: {
            /* 'slider-recurso': sliderecurso, */
            EditorJs
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            },
            urlToID(value) {

                let string;

                if (value.includes('youtube')) {
                    string = 'https://www.youtube.com/embed/' + value.split("?v=")[1];
                } else if (value.includes('vimeo')) {
                    string = 'https://player.vimeo.com/video/' + value.split("/").pop();
                }

                return string;
            }
        }
    }
</script>



<style scoped lang="scss">
    @import '@/assets/css/colors.scss';
    .ficha-recurso {
        margin-top: 100px;
        padding: 25px;
        min-height: 600px;
        margin-bottom: 100px;
        background: white;
        max-width: 1024px;

        .link-arrow {
            float: right;
        }

        .top {
            width: 100%;
            margin-bottom: 50px;

            img {
                max-width: 100%;
            }

            .fecha {
                font-size: 18px;
                font-weight: 300;
                text-align: left;
                width: 100%;
                display: inline-block;
                margin-bottom: 20px;
                color: $grey;
            }

            h2 {
                font-size: 40px;
                text-align: left;
                color: black;
                margin-bottom: 20px;
                display: inline-block;
                width: 100%;
            }

            .entradilla {
                width: 100%;
                display: inline-block;
                font-size: 20px;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 3px solid $yellow;
                color: black;
                text-align: left;
            }

            img {
                max-width: 100%;
                display: inline-block;
            }

        }

        .bottom {
            width: 100%;
            display: inline-block;
            margin-top: 20px;
            text-align: start;

            .contenedor-imagen {
                width: 100%;
                text-align: center;
                margin-bottom: 30px;

                img {
                    max-width: 100%;
                }

                .pie {
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    display: inline-block;
                    font-style: italic;
                }
            }

            p {
                margin-bottom: 20px;
                text-align: left;
                font-weight: 300;
                display: inline-block;
                width: 100%;
                font-size: 20px;

                b,strong {
                    font-weight: 800;
                }

                a {
                    text-decoration: underline;
                }
            }

            p:empty {
                display: none;
            }

            .destacado {
                font-size: 20px;
                text-align: left;
                font-weight: 800;
                line-height: 1.4em;
                margin-bottom: 15px;
                color: $yellow;

                &[nivel="1"] {
                    font-size: 24px;
                    max-width: 670px;
                    padding-left: 45px;
                    position: relative;
                    margin-bottom: 25px;
                    margin-top: 15px;
                }

                &[nivel="2"] {
                    font-size: 22px;
                }

                &[nivel="3"] {
                    font-size: 20px;
                }

                &[nivel="4"] {
                    font-size: 18px;
                }

                &[nivel="5"] {
                    font-size: 16px;
                }

                &[nivel="6"] {
                    font-size: 14px;
                }
            }

            iframe {
                width: 100%;
                border: none;
                height: 510px;
                margin-bottom: 25px;
            }

            .pie-noticia {
                padding-top: 20px;
                margin-top: 50px;
                border-top: 1px solid $yellow;
                width: 100%;
            }

            .contenedor-link {
                margin-bottom: 40px;
                margin-top: 40px;
            }

            ul {
                margin-bottom: 20px;
                padding-left: 30px;
            }

            ol {
                margin-bottom: 20px;
                list-style-type: decimal;
                padding-left: 30px;
            }

            ul li,
            ol li {
                width: 100%;
                text-align: left;
                margin-bottom: 10px;
                list-style: disc;
            }

            ol li {
                list-style-type: decimal;
            }

            .boton {
                margin-top: 30px;
                margin-bottom: 30px;

                .btn {
                    margin-top: 0;
                }
            }

            .link {
                width: 100%;
                display: inline-block;
                max-width: 450px;
                margin: 0 auto;
                position: relative;
                margin-top: 20px;
                margin-bottom: 20px;
                border: 1px solid $grey;
                padding: 25px;
                text-align: left;
                background: url(/img/go-to.svg) calc(100% - 10px) 10px/25px no-repeat;

                img {
                    max-width: 100%;
                }

                h2 {
                    font-weight: 800;
                    margin-bottom: 0px;
                    color: $yellow;
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .descargable {
                cursor: pointer;
                margin-bottom: 20px;
                position: relative;
                padding-bottom: 10px;
                border-bottom: 1px solid $yellow;

                p {
                    margin-bottom: 0;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .download-link {
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: 300;
                    color: $yellow;
                    text-decoration: none;
                    margin-left: 20px;

                    &::after {
                        width: 25px;
                        height: 25px;
                        background: url(/img/file.svg) center/100% no-repeat;
                        content: "";
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }

            .program-title {
                width: 100%;
                text-align: left;
                font-weight: 300;
                color: $yellow;
                display: inline-block;
                text-transform: uppercase;
                margin-bottom: 10px;
                margin-top: 20px;
            }

            .btn {
                margin-top: 50px;
            }



            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 40px;

                td {
                    padding: 10px;
                    border: 1px solid $grey;
                    text-align: left;
                    font-weight: 300;

                    b {
                        font-weight: 600;
                    }
                }
            }

        }

    }

    @media screen and (max-width:560px) {
        .ficha-recurso .top h2 {
            font-size: 32px;
        }

        .ficha-recurso .top .entradilla {
            font-size: 16px;
        }

        .ficha-recurso .top .fecha {
            font-size: 15px;
        }

        .ficha-recurso .bottom p{
            font-size: 16px;
            line-height: 1.2em;
        }

        .ficha-recurso .bottom .descargable p{
            flex-wrap: wrap;
            margin-bottom: 0;
            .download-link{
                margin-left: 0;
                margin-top: 4px;
            }
        }

    }
</style>